export const orgMonitorConfig = {'sections': [{'id': 1,
        'name': 'Показатели исполнения Стандарта в части требований к организационной структуре государственных учреждений службы занятости',
        'indicators': [{'id': 1,
            'name': 'Наличие в государственном учреждении службы занятости структурных подразделений в соответствии с требованиями пункта 8 Стандарта, балл',
            'base_indicator_name': 'Наличие в государственном учреждении службы занятости структурных подразделений в соответствии с требованиями пункта 8 Стандарта, балл',
            'base_indicator_type': 'boolean',
            'flk': null,
            'formula': 'q1 && q2',
            'level': 'УЦЗН',
            'sources': 'Положение об организационной структуре государственного учреждения службы занятости населения',
            'groups': [{'id': 0,
                'name': 'Структурные подразделения государственного учреждения службы занятости населения',
                'questions': [{'id': 1,
                    'name': 'Управляющий центр занятости населения (подразделения, осуществляющие централизованное управление функционированием центров занятости населения)',
                    'type': 'boolean',
                    'zone': null},
                    {'id': 2,
                        'name': 'Территориальные центры занятости населения (центры занятости населения в зависимости от численности населения в трудоспособном возрасте в муниципальных образованиях субъекта Российской Федерации)',
                        'type': 'boolean',
                        'zone': null}]}]},
            {'id': 2,
                'name': 'Соответствие основных функций управляющего центра занятости населения установленным требованиям, балл',
                'base_indicator_name': 'Соответствие основных функций управляющего центра занятости населения установленным требованиям, балл',
                'base_indicator_type': 'boolean',
                'flk': null,
                'formula': 'q1 && q2 && q3 && q4 && q5 && q6 && q7 && q8 && q9 && q10 && q11 && q12 && q13 && q14 && q15 && q16 && q17',
                'level': 'УЦЗН',
                'sources': 'Положение об организационной структуре государственного учреждения службы занятости населения',
                'groups': [{'id': 0,
                    'name': 'Основные функции управляющего центра занятости населения',
                    'questions': [{'id': 1,
                        'name': 'Организационно-методическое и консультационное обеспечение центров занятости населения при осуществлении ими своих функций',
                        'type': 'boolean',
                        'zone': null},
                        {'id': 2,
                            'name': 'Координация и управление деятельностью центров занятости населения:\nпри оказании государственных услуг, осуществлении полномочий в соответствии с законодательством о занятости населения, реализации мер активной политики занятости населения, дополнительных мероприятий в области содействия занятости населения, включая меры по содействию в трудоустройстве и занятости инвалидов',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 3,
                            'name': 'Координация и управление деятельностью центров занятости населения: \nпри участии в осуществлении мониторинга состояния рынка труда в субъекте Российской Федерации',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 4,
                            'name': 'Координация и управление деятельностью центров занятости населения:\nпри реализации государственных программ содействия занятости населения Российской Федерации и субъекта Российской Федерации',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 5,
                            'name': 'Координация и управление деятельностью центров занятости населения:\nпри организации дополнительных услуг',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 6,
                            'name': 'Координация и управление деятельностью центров занятости населения:\nпри осуществлении мероприятий по улучшению клиентского опыта',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 7,
                            'name': 'Координация и управление деятельностью центров занятости населения:\nпри информировании граждан и работодателей о предоставлении государственных услуг (осуществлении полномочий в области содействия занятости населения)',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 8,
                            'name': 'Осуществление социальных выплат гражданам, оказание финансовой поддержки и (или) материальной поддержки в соответствии с нормативными правовыми актами Российской Федерации и субъекта Российской Федерации (в том числе переданное на аутсорсинг полностью или частично)',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 9,
                            'name': 'Организация обучения и повышения квалификации сотрудников государственного учреждения службы занятости населения',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 10,
                            'name': 'Контроль (мониторинг) за деятельностью центров занятости населения при осуществлении ими своих функций',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 11,
                            'name': 'Централизованное материально-техническое обеспечение деятельности государственного учреждения службы занятости населения, закупка товаров, работ, услуг (в том числе переданное на аутсорсинг полностью или частично)',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 12,
                            'name': 'Централизованное управление персоналом государственного учреждения службы занятости населения, в том числе кадровое обеспечение и ведение кадрового делопроизводства',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 13,
                            'name': 'Централизованое ведение бюджетного учета и формирование бюджетной отчетности государственного учреждения службы занятости населения, в том числе начисление и выплаты заработной платы (в том числе переданное на аутсорсинг)',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 14,
                            'name': 'Централизованное правовое обеспечение деятельности государственного учреждения службы занятости населения',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 15,
                            'name': 'Централизованное информационное обеспечение деятельности государственного учреждения службы занятости населения и коммуникации',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 16,
                            'name': 'Централизованные информатизация и автоматизация деятельности государственного учреждения службы занятости населения',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 17,
                            'name': 'Разработка и внедрение региональных сервисов',
                            'type': 'boolean',
                            'zone': null}]}]},
            {'id': 3,
                'name': 'Соответствие основных функций территориальных центров занятости населения установленным требованиям, балл',
                'base_indicator_name': 'Соответствие основных функций территориальных центров занятости населения установленным требованиям, балл',
                'base_indicator_type': 'boolean',
                'flk': null,
                'formula': 'q1 && q2 && q3 && q4 && q5 && q6 && q7',
                'level': 'УЦЗН',
                'sources': 'Положение об организационной структуре государственного учреждения службы занятости населения',
                'groups': [{'id': 0,
                    'name': 'Основные функции территориальных центров занятости населения',
                    'questions': [{'id': 1,
                        'name': 'Взаимодействие с гражданами и работодателями:\nпри оказании государственных услуг, осуществлении полномочий в соответствии с законодательством о занятости населения, реализации мер активной политики занятости населения, дополнительных мероприятий и мер поддержки в области содействия занятости населения, включая меры по содействию в трудоустройстве и занятости инвалидов',
                        'type': 'boolean',
                        'zone': null},
                        {'id': 2,
                            'name': 'Взаимодействие с гражданами и работодателями:\nпри реализации государственных программ содействия занятости населения',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 3,
                            'name': 'Взаимодействие с гражданами и работодателями:\nпри организации дополнительных услуг',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 4,
                            'name': 'Регистрация граждан в целях поиска подходящей работы и регистрация безработных граждан',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 5,
                            'name': 'Назначение пособия по безработице, материальной помощи, финансовой поддержки, материальной поддержки, выдача предложения о досрочном назначении пенсии безработным гражданам',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 6,
                            'name': 'Осуществление мероприятий по улучшению клиентского опыта',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 7,
                            'name': 'Информирование граждан и работодателей о предоставлении государственных услуг (осуществлении полномочий) в области содействия занятости населения',
                            'type': 'boolean',
                            'zone': null}]}]},
            {'id': 4,
                'name': 'Доля территориальных центров занятости населения, соответствующих установленному типу, от общего количества территориальных центров занятости населения, процент',
                'base_indicator_name': 'Количество территориальных центров занятости населения, соответствующих установленному типу, шт.',
                'base_indicator_type': 'integer',
                'flk': "q1 + q2 + q3 != 1 && 'Выберите один вариант ответа'",
                'mutualExclusiveQuestions': {
                        "0": [1,2,3]
                      },
                'formula': 'CONST_CZN_LEVEL===1 && q1 || CONST_CZN_LEVEL===2 && q2 || CONST_CZN_LEVEL===3 && q3',
                'level': 'ТЦЗН',
                'sources': 'Положение об организационной структуре государственного учреждения службы занятости населения\nСведения о численности трудоспособного населения на территории обслуживания',
                'groups': [{'id': 0,
                    'name': 'Численность трудоспособного населения на территории обслуживания',
                    'questions': [{'id': 1,
                        'name': 'Более 50 тысяч человек',
                        'type': 'boolean',
                        'zone': null},
                        {'id': 2,
                            'name': 'От 10 тысяч до 50 тысяч человек',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 3, 'name': 'До 10 тысяч человек', 'type': 'boolean',
                            'zone': null}]}]}]},
        {'id': 2,
            'name': 'Показатели исполнения Стандарта в части требований к организации предоставления государственных услуг в области содействия занятости населения, иных государственных и муниципальных услуг (или их части), негосударственных услуг и мер поддержки, а также сервисов',
            'indicators': [{'id': 5,
                'name': 'Соответствие реестра мероприятий в сфере занятости и дополнительных услуг субъекта Российской Федерации установленным требованиям, балл',
                'base_indicator_name': 'Соответствие реестра мероприятий в сфере занятости и дополнительных услуг субъекта Российской Федерации установленным требованиям, балл',
                'base_indicator_type': 'boolean',
                'flk': null,
                'formula': 'q1 && q2 && q3 && q4 && q5 && q6',
                'level': 'УЦЗН',
                'sources': 'Порядок ведения реестра мероприятий в сфере занятости и дополнительных услуг субъекта Российской Федерации, утвержденный нормативным правовым актом субъекта Российской Федерации\nРеестр мероприятий в сфере занятости и дополнительных услуг субъекта Российской Федерации',
                'groups': [{'id': 0,
                    'name': 'Состав информации, которую содержит реестр мероприятий в сфере занятости и дополнительных услуг субъекта Российской Федерации',
                    'questions': [{'id': 1,
                        'name': 'Наименование, тип и вид мероприятий в сфере занятости или дополнительных услуг',
                        'type': 'boolean',
                        'zone': null},
                        {'id': 2,
                            'name': 'Наименование и тип поставщика мероприятий в сфере занятости или дополнительных услуг',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 3,
                            'name': 'Номер, дата, срок действия и условия соглашений с поставщиками мероприятий в сфере занятости или дополнительных услуг',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 4,
                            'name': 'Реквизиты нормативных правовых актов Российского Федерации или субъектов Российской Федерации, на основании которых осуществляются мероприятия в сфере занятости или оказываются дополнительные услуги',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 5,
                            'name': 'Тип(ы) территориальных центров занятости населения, в которых осуществляются мероприятия в сфере занятости или дополнительные услуги',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 6,
                            'name': 'Перечень территориальных центров занятости населения, в которых осуществляются мероприятия в сфере занятости или дополнительные услуги',
                            'type': 'boolean',
                            'zone': null}]}]},
                {'id': 6,
                    'name': 'Доля территориальных центров занятости населения, в которых организовано комплексное обслуживание граждан и работодателей, от общего количества территориальных центров занятости населения, процент',
                    'base_indicator_name': 'Количество территориальных центров занятости населения, в которых организовано комплексное обслуживание граждан и работодателей, шт.',
                    'base_indicator_type': 'integer',
                    'flk': null,
                    'formula': 'q1 >= 10 && q2 >= 5',
                    'level': 'ТЦЗН',
                    'sources': 'Правовые акты субъекта Российской Федерации, утверждающие технологические карты комплексов государственных услуг (сервисов), полномочий и/или дополнительных услуг в соответствии с жизненной или бизнес-ситуацией и содержащие список территориальных центров занятости населения, в которых эти комплексы предоставляются\nПравовые акты государственного учреждения службы занятости населения, утверждающие технологические карты комплексов государственных услуг (сервисов), полномочий и/или дополнительных услуг в соответствии с жизненной или бизнес-ситуацией',
                    'groups': [{'id': 0,
                        'name': 'Сведения об организации комплексного обслуживания граждан и работодателей',
                        'questions': [{'id': 1,
                            'name': 'Количество комплексов государственных услуг (сервисов), полномочий и/или дополнительных услуг в соответствии с жизненной ситуацией, включенной в Реестр жизненных ситуаций и бизнес-ситуаций, применяемых в целях формирования индивидуального плана при осуществлении полномочия в сфере занятости населения по организации и проведению специальных мероприятий по профилированию граждан, зарегистрированных в целях поиска подходящей работы, а также профилированию работодателей (Приложение 1 к Методическим рекомендациям для органов службы занятости по организации предоставления государственных услуг, полномочий в области содействия занятости населения, иных государственных и муниципальных услуг или их части, утв. приказом Минтруда России от 23.03.22 №158), шт',
                            'type': 'integer',
                            'zone': null},
                            {'id': 2,
                                'name': 'Количество комплексов государственных услуг (сервисов), полномочий и/или дополнительных услуг в соответствии с бизнес-ситуацией, включенной в Реестр жизненных ситуаций и бизнес-ситуаций, применяемых в целях формирования индивидуального плана при осуществлении полномочия в сфере занятости населения по организации и проведению специальных мероприятий по профилированию граждан, зарегистрированных в целях поиска подходящей работы, а также профилированию работодателей (Приложение 1 к Методическим рекомендациям для органов службы занятости по организации предоставления государственных услуг, полномочий в области содействия занятости населения, иных государственных и муниципальных услуг или их части, утв. приказом Минтруда России от 23.03.22 №158), шт',
                                'type': 'integer',
                                'zone': null}]}]}]},
        {'id': 3,
            'name': 'Показатели исполнения Стандарта в части требований к деятельности государственного учреждения службы занятости при взаимодействии с гражданами и работодателями',
            'indicators': [{'id': 7,
                'name': 'Доля территориальных центров занятости населения, в которых организовано взаимодействие в следующих точках (единая цифровая платформа; единый портал; официальный сайт исполнительного органа субъекта Российской Федерации, осуществляющего полномочия в области содействия занятости населения, и (или) государственного учреждения службы занятости населения; контакт-центр; электронная почта государственного учреждения службы занятости населения; электронная очередь; зона информирования граждан; зона информирования работодателей; зона первичного приема для граждан; зона первичного приема для работодателей; сектор цифровых сервисов для граждан; сектор цифровых сервисов для работодателей; зона индивидуальной работы с гражданами; зона индивидуальной работы с работодателями; зал групповых занятий (конференц-зал)), от общего количества территориальных центров занятости населения, процент',
                'base_indicator_name': 'Количество территориальных центров занятости населения, в которых организовано взаимодействие во всех перечисленных точках, шт',
                'base_indicator_type': 'integer',
                'flk': null,
                'formula': 'CONST_CZN_LEVEL===1 && q1 && q2 && q3 && q4 && q5 && q6 && q7 && q8 && q9 && q10 && q11 && q12 && q13 && q14 && q15\n||\nCONST_CZN_LEVEL===2 && q1 && q2 && q3 && q4 && q5 && q6 && q7 && q8 && q9 && q10 && q11 && q12 && q13 && q14 && q15\n||\nCONST_CZN_LEVEL===3 && q1 && q2 && q3 && q4 && q5 && q7 && q8 && q9 && q10 && q11',
                'level': 'ТЦЗН',
                'sources': 'Данные субъекта Российской Федерации, подтверждающие взаимодействие территориальных центров занятости населения в каждой точке взаимодействия (фото, техническая документация оборудования, планы зданий/помещений центров занятости населения)',
                'groups': [{'id': 0,
                    'name': 'Точки, в которых организовано взаимодействие с гражданами и работодателями',
                    'questions': [{'id': 1,
                        'name': 'Единая цифровая платформа в сфере занятости и трудовых отношений "Работа в России"',
                        'type': 'boolean',
                        'zone': null},
                        {'id': 2,
                            'name': 'Федеральная государственная информационная система "Единый портал государственных и муниципальных услуг (функций)"',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 3,
                            'name': 'Официальный сайт исполнительного органа субъекта Российской Федерации, осуществляющего полномочия в области содействия занятости населения, и (или) государственного учреждения службы занятости населения',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 4, 'name': 'Контакт-центр', 'type': 'boolean',
                            'zone': null},
                        {'id': 5,
                            'name': 'Электронная почта государственного учреждения службы занятости населения',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 6, 'name': 'Электронная очередь', 'type': 'boolean',
                            'zone': null},
                        {'id': 7, 'name': 'Зона информирования граждан', 'type': 'boolean',
                            'zone': null},
                        {'id': 8,
                            'name': 'Зона информирования работодателей',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 9,
                            'name': 'Зона первичного приема для граждан',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 10,
                            'name': 'Зона первичного приема для работодателей',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 11,
                            'name': 'Сектор цифровых сервисов для граждан',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 12,
                            'name': 'Сектор цифровых сервисов для работодателей',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 13,
                            'name': 'Зона индивидуальной работы с гражданами',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 14,
                            'name': 'Зона индивидуальной работы с работодателями',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 15,
                            'name': 'Зал групповых занятий (конференц-зал)',
                            'type': 'boolean',
                            'zone': null}]}]},
                {'id': 8,
                    'name': 'Доля территориальных центров занятости населения, в которых организована возможность осуществить предварительную запись на прием всеми способами, к которым установлены требования, от общего количества территориальных центров занятости населения, процент',
                    'base_indicator_name': 'Количество территориальных центров занятости населения, в которых организована возможность осуществить предварительную запись на прием всеми способами, к которым установлены требования, шт',
                    'base_indicator_type': 'integer',
                    'flk': null,
                    'formula': 'q1 && q2 && q3',
                    'level': 'ТЦЗН',
                    'sources': 'Данные субъекта Российской Федерации, подтверждающие организацию каждого канала осуществления предварительной записи на прием в территориальных центрах занятости населения для клиентов (фото, техническая документация оборудования, скриншоты)',
                    'groups': [{'id': 0,
                        'name': 'Способы осуществления предварительной записи',
                        'questions': [{'id': 1,
                            'name': 'По телефону (через контакт-центр)',
                            'type': 'boolean',
                            'zone': null},
                            {'id': 2,
                                'name': 'При личном обращении в центр занятости населения (в том числе через киоск электронной очереди)',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 3,
                                'name': 'Через официальный сайт исполнительного органа субъекта Российской Федерации, осуществляющего полномочия в области содействия занятости населения, и (или) государственного учреждения службы занятости населения',
                                'type': 'boolean',
                                'zone': null}]}]},
                {'id': 9,
                    'name': 'Соответствие контакт-центра государственного учреждения службы занятости установленным требованиям, балл',
                    'base_indicator_name': 'Соответствие контакт-центра государственного учреждения службы занятости установленным требованиям, балл',
                    'base_indicator_type': 'boolean',
                    'flk': null,
                    'formula': 'q1 && q2 && q3 && q4 && q5 && q6 && q7',
                    'level': 'УЦЗН',
                    'sources': 'Данные субъекта Российской Федерации, подтверждающие функциональные возможности контакт-центра (техническая документация на оборудование)',
                    'groups': [{'id': 0,
                        'name': 'Функции контакт-центра государственного учреждения службы занятости населения',
                        'questions': [{'id': 1,
                            'name': 'Одновременная работа нескольких телефонных линий, из расчета с учетом количества окон приема территориального центра занятости населения',
                            'type': 'boolean',
                            'zone': null},
                            {'id': 2,
                                'name': 'Переадресация звонков как на внутренние номера управляющего центра занятости населения, так и на номера территориальных центров занятости населения',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 3,
                                'name': 'Прием звонков не позднее третьего сигнала вызова',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 4,
                                'name': 'Информирование электронным голосовым автоответчиком с наиболее популярными ответами на вопросы',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 5,
                                'name': 'Трансляция каждые 30 секунд информации о прогнозируемом времени до ответа оператора на звонок гражданина или работодателя, если они выбирают в голосовом меню вариант соединения с оператором контакт-центра',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 6,
                                'name': 'Возможность оценить работу оператора контакт-центра после окончания обслуживания',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 7,
                                'name': 'Предоставление информации о работе контакт-центра, доступной руководству государственного учреждения службы занятости населения в виде еженедельных отчетов',
                                'type': 'boolean',
                                'zone': null}]}]},
                {'id': 10,
                    'name': 'Соответствие электронной почты государственного учреждения службы занятости установленным требованиям, балл',
                    'base_indicator_name': 'Соответствие электронной почты государственного учреждения службы занятости установленным требованиям, балл',
                    'base_indicator_type': 'boolean',
                    'flk': 'q1 == 0 && (q2 + q3 + q4 + q5 > 0) && "Запрещено выбирать - Да в пунктах 2-5, если в пункте 1 - Нет"',
                    'formula': 'q1 && q2 && q3 && q4 && q5',
                    'level': 'УЦЗН',
                    'sources': 'Данные субъекта Российской Федерации, подтверждающие функциональные возможности электронной почты государственного учреждения службы занятости населения (техническая документация)',
                    'groups': [{'id': 0,
                        'name': 'Функциональные возможности электронной почты государственного учреждения службы занятости населения',
                        'questions': [{'id': 1,
                            'name': 'Наличие собственного адреса электронной почты',
                            'type': 'boolean',
                            'zone': null},
                            {'id': 2,
                                'name': 'Размещение адреса электронной почты на официальном сайте государственного учреждения службы занятости населения (при его наличии)',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 3,
                                'name': 'Размещение адреса электронной почты на страницах государственного учреждения службы занятости населения в социальных сетях',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 4,
                                'name': 'Направление клиенту уведомления о получении письма, в котором обозначены сроки ответа',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 5,
                                'name': 'Срок ответа на направленные на электронную почту письма клиентов составляет не более 3-х рабочих дней, если иное не предусмотрено нормативными правовыми актами Российской Федерации',
                                'type': 'boolean',
                                'zone': null}]}]},
                {'id': 11,
                    'name': 'Доля территориальных центров занятости населения, в которых график работы соответствует установленным требованиям, от общего количества территориальных центров занятости населения, процент',
                    'base_indicator_name': 'Количество территориальных центров занятости населения, в которых график работы соответствует установленным требованиям, шт',
                    'base_indicator_type': 'integer',
                    'flk': '(q1 + q2 + q3 + q4 + q5 + q6 + q7 + q8 > 1 && "В группе «Численность трудоспособного населения на территории обслуживания» запрещено проставлять более одного значения «Да»") || (q9 + q10 + q11 > 1 && "В группе «График работы» запрещено проставлять более одного значения «Да» среди пунктов 1-3")',
                    'mutualExclusiveQuestions': {
                        "0": [1,2,3,4,5,6,7,8],
                        "1": [9,10,11]
                      },
                    'formula': '(q1 && q10 && q12 && q14)\n||\n(q2 && q10 && q12 && q14)\n||\n(q3 && q10 && q12 && q14)\n||\n(q4 && q10 && q12)\n||\n(q5 && (q9 || q10) && q12)\n||\n(q6 && (q11 || q9) && q12)\n||\n(q7 && (q11 || q9 || q8) && (q11 || q9))',
                    'level': 'ТЦЗН',
                    'sources': 'Данные субъекта Российской Федерации, подтверждающие график работы государственного учреждения службы занятости населения (приказы о графике работы государственного учреждения службы занятости населения, сведения о численности трудоспособного населения)',
                    'groups': [{'id': 0,
                        'name': 'Численность трудоспособного населения на территории обслуживания',
                        'questions': [{'id': 1,
                            'name': 'Свыше 1 млн. человек',
                            'type': 'boolean',
                            'zone': null},
                            {'id': 2, 'name': 'От 500 тысяч до 1 млн. человек', 'type': 'boolean',
                                'zone': null},
                            {'id': 3,
                                'name': 'От 300 тысяч до 500 тысяч человек',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 4,
                                'name': 'От 200 тысяч до 300 тысяч человек',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 5,
                                'name': 'От 50 тысяч до 200 тысяч человек',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 6,
                                'name': 'От 30 тысяч до 50 тысяч человек',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 7,
                                'name': 'От 10 тысяч до 30 тысяч человек',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 8, 'name': 'До 10 тысяч человек', 'type': 'boolean',
                                'zone': null}]},
                        {'id': 1,
                            'name': 'График работы',
                            'questions': [{'id': 9,
                                'name': 'Очный прием граждан и работодателей не менее 40 часов в неделю',
                                'type': 'boolean',
                                'zone': null},
                                {'id': 10,
                                    'name': 'Очный прием граждан и работодателей не менее 45 часов в неделю',
                                    'type': 'boolean',
                                    'zone': null},
                                {'id': 11,
                                    'name': 'Очный прием граждан и работодателей не менее 30 часов в неделю',
                                    'type': 'boolean',
                                    'zone': null},
                                {'id': 12,
                                    'name': 'Очный прием клиентов в один из рабочих дней недели в вечернее время до 20 часов',
                                    'type': 'boolean',
                                    'zone': null},
                                {'id': 13,
                                    'name': 'Очный прием граждан и работодателей не менее чем в два из рабочих дней недели в вечернее время до 20 часов',
                                    'type': 'boolean',
                                    'zone': null},
                                {'id': 14,
                                    'name': 'Прием клиентов без перерыва в течение рабочего дня',
                                    'type': 'boolean',
                                    'zone': null}]}]},
                {'id': 12,
                    'name': 'Доля территориальных центров занятости населения, в секторах цифровых сервисов которых организован доступ ко всем информационным ресурсам, указанным в подпункте «а» пункта 44 Стандарта, от общего количества территориальных центров занятости населения, процент',
                    'base_indicator_name': 'Количество территориальных центров занятости населения, в секторах цифровых сервисов которых организован доступ ко всем информационным ресурсам, указанным в подпункте «а» пункта 44 Стандарта, шт',
                    'base_indicator_type': 'integer',
                    'flk': null,
                    'formula': 'q1 && q2 && q4 && q5 && q6 && q7',
                    'level': 'ТЦЗН',
                    'sources': 'Данные субъекта Российской Федерации, подтверждающие организацию доступа к требуемым информационным ресурсам в секторах цифровых сервисов (фото)',
                    'groups': [
                        {'id': 0,
                            'name': 'Информационные ресурсы, к которым организован бесплатный доступ в секторе цифровых сервисов для граждан и работодателей',
                            'questions': [{'id': 1,
                                'name': 'Единая цифровая платформа в сфере занятости и трудовых отношений "Работа в России"',
                                'type': 'boolean',
                                'zone': null},
                                {'id': 2,
                                    'name': 'Федеральная государственная информационная система "Единый портал государственных и муниципальных услуг (функций)"',
                                    'type': 'boolean',
                                    'zone': null},
                                {'id': 3,
                                    'name': 'Региональный портал государственных и муниципальных услуг (при наличии)',
                                    'type': 'boolean',
                                    'zone': null},
                                {'id': 4,
                                    'name': 'Информационно-правовая система «Официальный интернет-портал правовой информации»',
                                    'type': 'boolean',
                                    'zone': null},
                                {'id': 5,
                                    'name': 'Официальные сайты федеральных органов исполнительной власти',
                                    'type': 'boolean',
                                    'zone': null},
                                {'id': 6,
                                    'name': 'Официальные сайты органов государственной власти субъектов Российской Федерации',
                                    'type': 'boolean',
                                    'zone': null},
                                {'id': 7,
                                    'name': 'Система электронных сервисов информационного портала «Онлайнинспекция.рф»',
                                    'type': 'boolean',
                                    'zone': null}]}
                    ]},
                {'id': 13,
                    'name': 'Доля территориальных центров занятости населения, в секторах цифровых сервисов которых организовано консультационное сопровождение граждан и работодателей, от общего количества территориальных центров занятости населения, процент',
                    'base_indicator_name': 'Количество территориальных центров занятости населения, в секторах цифровых сервисов которых организовано консультационное сопровождение граждан и работодателей, шт',
                    'base_indicator_type': 'integer',
                    'flk': null,
                    'formula': 'q1 && q2',
                    'level': 'ТЦЗН',
                    'sources': 'Данные субъекта Российской Федерации, подтверждающие осуществление консультационного сопровождения в секторах цифровых сервисов (штатное расписание, должностные инструкции, журнал учета консультаций)',
                    'groups': [{'id': 0,
                        'name': 'Типы консультационного сопровождения в секторах цифровых сервисов',
                        'questions': [{'id': 1,
                            'name': 'Консультационное сопровождение граждан в секторе цифровых сервисов для граждан',
                            'type': 'boolean',
                            'zone': null},
                            {'id': 2,
                                'name': 'Консультационное сопровождение работодателей в секторе цифровых сервисов для работодателей',
                                'type': 'boolean',
                                'zone': null}]}]},
                {'id': 14,
                    'name': 'Доля территориальных центров занятости населения, секторы цифровых сервисов для граждан которых оборудованы автоматизированными рабочими местами для граждан (планшетными компьютерами/электронными планшетами) в соответствии с установленными требованиями, от общего количества территориальных центров занятости населения, процент',
                    'base_indicator_name': 'Количество территориальных центров занятости населения, секторы цифровых сервисов для граждан которых оборудованы автоматизированными рабочими местами для граждан (планшетными компьютерами/электронными планшетами) в соответствии с установленными требованиями, шт',
                    'base_indicator_type': 'integer',
                    'flk': 'q2 >= 1 && !this.localAttachments.find(e => !e.deleted && e.source !== "Иные") && "Прикрепите документы"',
                    'formula': '(q1 < 5 && q2 >=1) || (q1 >= 5 && q2 >= q1/5)',
                    'level': 'ТЦЗН',
                    'sources': 'Данные субъекта Российской Федерации, подтверждающие обеспечение сектора цифровых сервисов для граждан автоматизированными рабочими местами (планшетными компьютерами/ электронными планшетами) (план помещений с указанием количества окон, фото)',
                    'groups': [{'id': 0,
                        'name': 'Сведения об автоматизированных рабочих местах для граждан',
                        'questions': [{'id': 1,
                            'name': 'Количество окон приема в зоне первичного приема граждан, шт',
                            'type': 'integer',
                            'zone': null},
                            {'id': 2,
                                'name': 'Количество автоматизированных рабочих мест для граждан (планшетных компьютеров/электронных планшетов), шт',
                                'type': 'integer',
                                'zone': null}]}]},
                {'id': 15,
                    'name': 'Доля территориальных центров занятости населения, секторы цифровых сервисов для работодателей которых оборудованы автоматизированными рабочими местами для работодателей (планшетными компьютерами/электронными планшетами) в соответствии с установленными требованиями, от общего количества территориальных центров занятости населения, процент',
                    'base_indicator_name': 'Количество территориальных центров занятости населения, секторы цифровых сервисов для работодателей которых оборудованы автоматизированными рабочими местами для работодателей (планшетными компьютерами/электронными планшетами) в соответствии с установленными требованиями, шт',
                    'base_indicator_type': 'integer',
                    'flk': '(q3 === 1 && q1 + q2 >= 1 && "Запрещено вносить сведения, если выбрано совмещение") ' +
                        '|| (q3 === 1 && this.localAttachments.find(e => !e.deleted) && "Запрещено прикреплять файлы, если выбрано совмещение") ' +
                        '|| (q2 >= 1 && !this.localAttachments.find(e => !e.deleted && e.source !== "Иные") && "Прикрепите документы")',
                    'formula': '(q1 < 5 && q2 >=1) || (q1 >= 5 && q2 >= q1/5)',
                    'level': 'ТЦЗН',
                    'sources': 'Данные субъекта Российской Федерации, подтверждающие обеспечение сектора цифровых сервисов для работодателей автоматизированными рабочими местами (планшетными компьютерами/ электронными планшетами) (план помещений с указанием количества окон, фото)',
                    'groups': [
                        {
                            'id': 0,
                            'name': 'Совмещение сектора цифровых сервисов для граждан и работодателей',
                            'questions': [{
                                'id': 3,
                                'name': 'Сектор цифровых сервисов для граждан и сектор цифровых сервисов для работодателей совмещены',
                                'type': 'boolean',
                                'zone': null,
                            }]
                        },
                        {
                            'id': 1,
                            'name': 'Сведения об автоматизированных рабочих местах для работодателей',
                            'questions': [
                                {
                                    'id': 1,
                                    'name': 'Количество окон приема в зоне первичного приема работодателей, шт',
                                    'type': 'integer',
                                    'zone': null
                                },
                                {
                                    'id': 2,
                                    'name': 'Количество автоматизированных рабочих мест для работодателей (планшетных компьютеров/электронных планшетов), шт',
                                    'type': 'integer',
                                    'zone': null
                                }
                            ]}
                    ]},
                {'id': 16,
                    'name': 'Доля территориальных центров занятости населения, в которых среднее время ожидания в очереди не превышает 15 минут, от общего количества территориальных центров занятости населения, процент',
                    'base_indicator_name': 'Количество территориальных центров занятости населения, в которых среднее время ожидания в очереди не превышает 15 минут, шт',
                    'base_indicator_type': 'integer',
                    'flk': 'this.localValues[0]?.comment !== "" && q1 >= 0 && !this.localAttachments.find(e => !e.deleted && e.source !== "Иные") && "Прикрепите документы"',
                    'formula': 'q1<=15',
                    'level': 'ТЦЗН',
                    'sources': 'Отчеты о времени ожидания в очереди при обращении клиента в территориальные центры занятости населения',
                    'groups': [{'id': 0,
                        'name': 'Сведения о среднем времени ожидания в очереди',
                        'questions': [{'id': 1,
                            'name': 'Среднее время ожидания в очереди, мин',
                            'type': 'integer',
                            'zone': null}]}]}]},
        {'id': 4,
            'name': 'Показатели исполнения Стандарта в части требований к системе управления клиентским опытом',
            'indicators': [{'id': 17,
                'name': 'Соответствие количества сформированных государственным учреждением службы занятости профилей клиентских групп установленным требованиям, балл',
                'base_indicator_name': 'Соответствие количества сформированных государственным учреждением службы занятости профилей клиентских групп установленным требованиям, балл',
                'base_indicator_type': 'boolean',
                'flk': null,
                'formula': 'q1 >= 10',
                'level': 'УЦЗН',
                'sources': 'Данные субъектов Российской Федерации, подтверждающие наличие разработанных и внедренных профилей клиентских групп (профили клиентских групп)\nПротоколы региональной Рабочей группы по клиентоцентричности, утверждающие профили клиентских групп',
                'groups': [{'id': 0,
                    'name': 'Сведения о профилях клиентских групп',
                    'questions': [{'id': 1,
                        'name': 'Количество сформированных государственным учреждением службы занятости населения профилей клиентских групп, шт',
                        'type': 'integer',
                        'zone': null}]}]},
                {'id': 18,
                    'name': 'Соответствие количества сформированных государственным учреждением службы занятости клиентских маршрутов установленным требованиям, балл',
                    'base_indicator_name': 'Соответствие количества сформированных государственным учреждением службы занятости клиентских маршрутов установленным требованиям, балл',
                    'base_indicator_type': 'boolean',
                    'flk': null,
                    'formula': 'q1 >= 10',
                    'level': 'УЦЗН',
                    'sources': 'Данные субъектов Российской Федерации, подтверждающие наличие разработанных и внедренных маршрутов клиентов (клиентские маршруты)\nПротоколы региональной рабочей группы по клиентоцентричности, утверждающие клиентские маршруты',
                    'groups': [{'id': 0,
                        'name': 'Сведения о клиентских маршрутах',
                        'questions': [{'id': 1,
                            'name': 'Количество сформированных государственным учреждением службы занятости населения клиентских маршрутов, шт',
                            'type': 'integer',
                            'zone': null}]}]},
                {'id': 19,
                    'name': 'Соответствие количества и состава источников, из которых осуществляется сбор информации для измерения качества клиентского опыта в точках взаимодействия клиента и государственного учреждения службы занятости, установленным требованиям, балл',
                    'base_indicator_name': 'Соответствие количества и состава источников, из которых осуществляется сбор информации для измерения качества клиентского опыта в точках взаимодействия клиента и государственного учреждения службы занятости, установленным требованиям, балл',
                    'base_indicator_type': 'boolean',
                    'flk': null,
                    'formula': 'q1 && q2 && q3 && q4 && q5',
                    'level': 'УЦЗН',
                    'sources': 'Данные субъекта Российской Федерации, подтверждающие наличие системы сбора информации для измерения качества клиентского опыта в точках взаимодействия клиента и государственного учреждения службы занятости населения (описание системы сбора обратной связи)\nПротоколы региональной рабочей группы по клиентоцентричности, утверждающие количество и состав источников, из которых осуществляется сбор информации для измерения качества клиентского опыта',
                    'groups': [{'id': 0,
                        'name': 'Источники, из которых осуществляется сбор информации для измерения качества клиентского опыта в точках взаимодействия клиента и государственного учреждения службы занятости населения',
                        'questions': [{'id': 1,
                            'name': 'Книга жалоб и предложений',
                            'type': 'boolean',
                            'zone': null},
                            {'id': 2,
                                'name': 'Информация о поступивших обращениях (жалобах) граждан и работодателей',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 3,
                                'name': 'Информация из открытых источников (средств массовой информации, социальных сетей и других источников)',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 4, 'name': 'Опросы граждан и работодателей', 'type': 'boolean',
                                'zone': null},
                            {'id': 5,
                                'name': 'Инструменты оценки удовлетворенности граждан и работодателей предоставлением государственных услуг (метрики оценки удовлетворенности)',
                                'type': 'boolean',
                                'zone': null}]}]},
                {'id': 20,
                    'name': 'Наличие единой базы данных об анализе качества клиентского опыта, балл',
                    'base_indicator_name': 'Наличие единой базы данных об анализе качества клиентского опыта, балл',
                    'base_indicator_type': 'boolean',
                    'flk': null,
                    'formula': 'q1',
                    'level': 'УЦЗН',
                    'sources': 'Регламент ведения единой базы данных об анализе качества клиентского опыта, утвержденный руководителем государственного учреждения службы занятости населения\nОтчет о результатах анализа качества клиентского опыта',
                    'groups': [{'id': 0,
                        'name': 'Сведения о единой базе данных об анализе качества клиентского опыта',
                        'questions': [{'id': 1,
                            'name': 'Наличие единой базы данных об анализе качества клиентского опыта',
                            'type': 'boolean',
                            'zone': null}]}]},
                {'id': 21,
                    'name': 'Наличие целевых значений клиентских показателей эффективности, балл',
                    'base_indicator_name': 'Наличие целевых значений клиентских показателей эффективности, балл',
                    'base_indicator_type': 'boolean',
                    'flk': null,
                    'formula': 'q1',
                    'level': 'УЦЗН',
                    'sources': 'Протоколы региональной рабочей группы по клиентоцентричности, утверждающие целевые значения клиентских показателей эффективности',
                    'groups': [{'id': 0,
                        'name': 'Сведения о целевых значениях клиентских показателей эффективности',
                        'questions': [{'id': 1,
                            'name': 'Наличие целевых значений клиентских показателей эффективности',
                            'type': 'boolean',
                            'zone': null}]}]},
                {'id': 22,
                    'name': 'Наличие в структуре управляющего центра занятости населения структурного подразделения – службы дизайна клиентского опыта, балл',
                    'base_indicator_name': 'Наличие в структуре управляющего центра занятости населения структурного подразделения – службы дизайна клиентского опыта, балл',
                    'base_indicator_type': 'boolean',
                    'flk': null,
                    'formula': 'q1',
                    'level': 'УЦЗН',
                    'sources': 'Положение о структурном подразделении - службе дизайна клиентского опыта',
                    'groups': [{'id': 0,
                        'name': 'Структурное подразделение управляющего центра занятости населения',
                        'questions': [{'id': 1,
                            'name': 'Служба дизайна клиентского опыта',
                            'type': 'boolean',
                            'zone': null}]}]},
                {'id': 23,
                    'name': 'Соответствие периодичности заседаний рабочей группы по клиентоцентричности установленным требованиям, балл',
                    'base_indicator_name': 'Соответствие периодичности заседаний рабочей группы по клиентоцентричности установленным требованиям, балл',
                    'base_indicator_type': 'boolean',
                    'flk': 'this.period === 4 && q7 + q8 + q9 + q10 + q11 + q12 >= 1 && "Нельзя указывать положительные ответы в полях 7 - 12, если выбран период за пол года"',
                    'formula': "(CONST_INTERVAL==='fullyear' && q1>=1 && q2>=1 && q3>=1 && q4>=1 && q5>=1 && q6>=1 && q7>=1 && q8>=1 && q9>=1 && q10>=1 && q11>=1 && q12>=1)\n||\n(CONST_INTERVAL==='half_year' && q1>=1 && q2>=1 && q3>=1 && q4>=1 && q5>=1 && q6>=1)",
                    'level': 'УЦЗН',
                    'sources': 'Протоколы региональной рабочей группы по клиентоцентричности\nПоложение о региональной рабочей группе',
                    'groups': [{'id': 0,
                        'name': 'Количество заседаний рабочей группы по клиентоцентричности',
                        'questions': [{'id': 1,
                            'name': 'Количестве заседаний рабочей группы по клиентоцентричности в январе, шт',
                            'type': 'integer',
                            'zone': null},
                            {'id': 2,
                                'name': 'Количестве заседаний рабочей группы по клиентоцентричности в феврале, шт',
                                'type': 'integer',
                                'zone': null},
                            {'id': 3,
                                'name': 'Количестве заседаний рабочей группы по клиентоцентричности в марте, шт',
                                'type': 'integer',
                                'zone': null},
                            {'id': 4,
                                'name': 'Количестве заседаний рабочей группы по клиентоцентричности в апреле, шт',
                                'type': 'integer',
                                'zone': null},
                            {'id': 5,
                                'name': 'Количестве заседаний рабочей группы по клиентоцентричности в мае, шт',
                                'type': 'integer',
                                'zone': null},
                            {'id': 6,
                                'name': 'Количестве заседаний рабочей группы по клиентоцентричности в июне, шт',
                                'type': 'integer',
                                'zone': null},
                            {'id': 7,
                                'name': 'Количестве заседаний рабочей группы по клиентоцентричности в июле, шт',
                                'type': 'integer',
                                'zone': null},
                            {'id': 8,
                                'name': 'Количестве заседаний рабочей группы по клиентоцентричности в августе, шт',
                                'type': 'integer',
                                'zone': null},
                            {'id': 9,
                                'name': 'Количестве заседаний рабочей группы по клиентоцентричности в сентябре, шт',
                                'type': 'integer',
                                'zone': null},
                            {'id': 10,
                                'name': 'Количестве заседаний рабочей группы по клиентоцентричности в октябре, шт',
                                'type': 'integer',
                                'zone': null},
                            {'id': 11,
                                'name': 'Количестве заседаний рабочей группы по клиентоцентричности в ноябре, шт',
                                'type': 'integer',
                                'zone': null},
                            {'id': 12,
                                'name': 'Количестве заседаний рабочей группы по клиентоцентричности в декабре, шт',
                                'type': 'integer',
                                'zone': null}]}]},
                {'id': 24,
                    'name': 'Соответствие периодичности проведения мероприятий для развития клиентоцентричных компетенций работников установленным требованиям, балл',
                    'base_indicator_name': 'Соответствие периодичности проведения мероприятий для развития клиентоцентричных компетенций работников установленным требованиям, балл',
                    'base_indicator_type': 'boolean',
                    'flk': 'this.period === 4 && q3 + q4 >= 1 && "Нельзя указывать положительные ответы в полях 3 и 4, если выбран период за пол года"',
                    'formula': "(CONST_INTERVAL==='fullyear' && q1>=1 && q2>=1 && q3>=1 && q4>=1)\n||\n(CONST_INTERVAL==='half_year' && q1>=1 && q2>=1)",
                    'level': 'УЦЗН',
                    'sources': 'Протоколы региональной рабочей группы по клиентоцентричности\nДанные субъекта Российской Федерации, подтверждающие наличие мероприятий по повышению клиентоцентричности (отчет о мероприятии, фотографии)',
                    'groups': [{'id': 0,
                        'name': 'Количество мероприятий для развития клиентоцентричных компетенций работников',
                        'questions': [{'id': 1,
                            'name': 'Количество мероприятий для развития клиентоцентричных компетенций работников в I кв., шт',
                            'type': 'integer',
                            'zone': null},
                            {'id': 2,
                                'name': 'Количество мероприятий для развития клиентоцентричных компетенций работников в II кв., шт',
                                'type': 'integer',
                                'zone': null},
                            {'id': 3,
                                'name': 'Количество мероприятий для развития клиентоцентричных компетенций работников в III кв., шт',
                                'type': 'integer',
                                'zone': null},
                            {'id': 4,
                                'name': 'Количество мероприятий для развития клиентоцентричных компетенций работников в IV кв., шт',
                                'type': 'integer',
                                'zone': null}]}]},
                {'id': 25,
                    'name': 'Наличие базы знаний, содержащей успешные практики, информационные материалы, решения по повышению качества клиентского опыта, балл',
                    'base_indicator_name': 'Наличие базы знаний, содержащей успешные практики, информационные материалы, решения по повышению качества клиентского опыта, балл',
                    'base_indicator_type': 'boolean',
                    'flk': null,
                    'formula': 'q1',
                    'level': 'УЦЗН',
                    'sources': 'Протоколы региональной рабочей группы по клиентоцентричности\nДанные субъекта Российской Федерации, подтверждающие наличие базы знаний (банка решений) по вопросам, связанным с функционированием системы управления клиентским опытом (ссылки, скриншоты, документы, описывающие базу знаний, ее расположение и функционирование)',
                    'groups': [{'id': 0,
                        'name': 'Сведения о базе знаний, содержащей успешные практики, информационные материалы, решения по повышению качества клиентского опыта',
                        'questions': [{'id': 1,
                            'name': 'Наличие базы знаний, содержащей успешные практики, информационные материалы, решения по повышению качества клиентского опыта',
                            'type': 'boolean',
                            'zone': null}]}]}]},
        {'id': 5,
            'name': 'Показатели исполнения Стандарта в части требований к организации процессов управления персоналом государственных учреждений службы занятости',
            'indicators': [{'id': 26,
                'name': 'Наличие в структуре управляющего центра занятости населения структурного подразделения – службы по работе с персоналом, балл',
                'base_indicator_name': 'Наличие в структуре управляющего центра занятости населения службы по работе с персоналом, балл',
                'base_indicator_type': 'boolean',
                'flk': null,
                'formula': 'q1',
                'level': 'УЦЗН',
                'sources': 'Положение о структурном подразделении - службе по работе с персоналом',
                'groups': [{'id': 0,
                    'name': 'Структурное подразделение управляющего центра занятости населения',
                    'questions': [{'id': 1,
                        'name': 'Служба по работе с персоналом',
                        'type': 'boolean',
                        'zone': null}]}]},
                {'id': 27,
                    'name': 'Наличие ежегодного плана мероприятий в сфере управления персоналом по следующим направлениям работы с персоналом: подбор, адаптация, обучение и развитие персонала, мотивация, оценка, развитие корпоративной культуры, балл',
                    'base_indicator_name': 'Наличие ежегодного плана мероприятий в сфере управления персоналом по следующим направлениям работы с персоналом: подбор, адаптация, обучение и развитие персонала, мотивация, оценка, развитие корпоративной культуры, балл',
                    'base_indicator_type': 'boolean',
                    'flk': null,
                    'formula': 'q1 && q2 && q3 && q4 && q5 && q6 && q7',
                    'level': 'УЦЗН',
                    'sources': 'План мероприятий в сфере управления персоналом',
                    'groups': [{'id': 0,
                        'name': 'Сведения о ежегодном плане мероприятий в сфере управления персоналом',
                        'questions': [{'id': 1,
                            'name': 'Наличие плана мероприятий в сфере управления персоналом на текущий год, в том числе по следующим направлениям работы:',
                            'type': 'boolean',
                            'zone': null},
                            {'id': 2, 'name': 'подбор персонала', 'type': 'boolean',
                                'zone': null},
                            {'id': 3, 'name': 'адаптация персонала', 'type': 'boolean',
                                'zone': null},
                            {'id': 4, 'name': 'обучение и развитие персонала', 'type': 'boolean',
                                'zone': null},
                            {'id': 5, 'name': 'мотивация персонала', 'type': 'boolean',
                                'zone': null},
                            {'id': 6, 'name': 'оценка персонала', 'type': 'boolean',
                                'zone': null},
                            {'id': 7,
                                'name': 'развитие корпоративной культуры',
                                'type': 'boolean',
                                'zone': null}]}]},
                {'id': 28,
                    'name': 'Наличие утвержденных положений о подборе персонала, об адаптации персонала, об обучении персонала, балл',
                    'base_indicator_name': 'Наличие утвержденных положений о подборе персонала, об адаптации персонала, об обучении персонала, балл',
                    'base_indicator_type': 'boolean',
                    'flk': '!this.localAttachments.find(e => e.source === "Положение о подборе персонала" && !e.deleted) && q1 >= 1 && "Не загружен файл в раздел Положение о подборе персонала" ' +
                        '|| !this.localAttachments.find(e => e.source === "Положение об адаптации персонала" && !e.deleted) && q2 >= 1 && "Не загружен файл в раздел Положение об адаптации персонала" ' +
                        '|| !this.localAttachments.find(e => e.source === "Положение об обучении персонала" && !e.deleted) && q3 >= 1 && "Не загружен файл в раздел Положение об обучении персонала" ' +
                        '|| this.localAttachments.find(e => e.source === "Положение о подборе персонала" && !e.deleted) && q1 != 1 && "Загружен файл в раздел Положение о подборе персонала при выборе отрицательного ответа" ' +
                        '|| this.localAttachments.find(e => e.source === "Положение об адаптации персонала" && !e.deleted) && q2 != 1 && "Загружен файл в раздел Положение об адаптации персонала при выборе отрицательного ответа" ' +
                        '|| this.localAttachments.find(e => e.source === "Положение об обучении персонала" && !e.deleted) && q3 != 1 && "Загружен файл в раздел Положение об обучении персонала при выборе отрицательного ответа" ',
                    'formula': 'q1 && q2 && q3',
                    'level': 'УЦЗН',
                    'sources': 'Положение о подборе персонала\nПоложение об адаптации персонала\nПоложение об обучении персонала',
                    'groups': [{'id': 0,
                        'name': 'Сведения о положениях о подборе персонала, об адаптации персонала, об обучении персонала',
                        'questions': [{'id': 1,
                            'name': 'Наличие положения о подборе персонала',
                            'type': 'boolean',
                            'zone': null},
                            {'id': 2,
                                'name': 'Наличие положения об адаптации персонала',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 3,
                                'name': 'Наличие положения об обучении персонала',
                                'type': 'boolean',
                                'zone': null}]}]},
                {'id': 29,
                    'name': 'Наличие отдельного раздела на сайте государственного учреждения службы занятости для кандидатов на вакансии, в том числе студентов, которые хотели бы пройти стажировку или практику в государственном учреждении службы занятости, балл',
                    'base_indicator_name': 'Наличие отдельного раздела на сайте государственного учреждения службы занятости для кандидатов на вакансии, в том числе студентов, которые хотели бы пройти стажировку или практику в государственном учреждении службы занятости, балл',
                    'base_indicator_type': 'boolean',
                    'flk': null,
                    'formula': 'q1',
                    'level': 'УЦЗН',
                    'sources': 'Раздел на сайте государственного учреждения службы занятости населения для кандидатов на вакансии, в том числе студентов, которые хотели бы пройти стажировку или практику в государственном учреждении службы занятости населения (ссылка)',
                    'groups': [{'id': 0,
                        'name': 'Сведения о разделе на сайте государственного учреждения службы занятости населения',
                        'questions': [{'id': 1,
                            'name': 'Наличие отдельного раздела на сайте государственного учреждения службы занятости населения для кандидатов на вакансии, в том числе студентов, которые хотели бы пройти стажировку или практику в государственном учреждении службы занятости населения',
                            'type': 'boolean',
                            'zone': null}]}]}]},
        {'id': 6,
            'name': 'Показатели исполнения Стандарта в части требований к информационному сопровождению деятельности государственных учреждений службы занятости',
            'indicators': [{'id': 30,
                'name': 'Наличие в структуре управляющего центра занятости населения структурного подразделения – пресс-службы, балл',
                'base_indicator_name': 'Наличие в структуре управляющего центра занятости населения пресс-службы, балл',
                'base_indicator_type': 'boolean',
                'flk': null,
                'formula': 'q1',
                'level': 'УЦЗН',
                'sources': 'Положение о структурном подразделении -пресс-службе Должностные инструкции сотрудников пресс-службы',
                'groups': [{'id': 0,
                    'name': 'Структурное подразделение управляющего центра занятости населения',
                    'questions': [{'id': 1, 'name': 'Пресс-служба', 'type': 'boolean',
                        'zone': null}]}]},
                {'id': 31,
                    'name': 'Доля территориальных центров занятости населения, в которых назначен работник, ответственный за взаимодействие с пресс-службой, от общего количества территориальных центров занятости населения, процент',
                    'base_indicator_name': 'Количество территориальных центров занятости населения, в которых назначен работник, ответственный за взаимодействие с пресс-службой., шт',
                    'base_indicator_type': 'integer',
                    'flk': null,
                    'formula': 'q1>=1',
                    'level': 'ТЦЗН',
                    'sources': 'Приказы государственного учреждения службы занятости населения о назначении ответственных',
                    'groups': [{'id': 0,
                        'name': 'Сведения о назначении работников, ответственных за взаимодействие с пресс-службой',
                        'questions': [{'id': 1,
                            'name': 'Количество работников, ответственных за взаимодействие с пресс-службой, ед.',
                            'type': 'integer',
                            'zone': null}]}]},
                {'id': 32,
                    'name': 'Наличие ежегодного плана информационного сопровождения государственного учреждения службы занятости, балл',
                    'base_indicator_name': 'Наличие ежегодного плана информационного сопровождения государственного учреждения службы занятости, балл',
                    'base_indicator_type': 'boolean',
                    'flk': null,
                    'formula': 'q1',
                    'level': 'УЦЗН',
                    'sources': 'План информационного сопровождения государственного учреждения службы занятости населения',
                    'groups': [{'id': 0,
                        'name': 'Сведения о ежегодном плане информационного сопровождения государственного учреждения службы занятости населения',
                        'questions': [{'id': 1,
                            'name': 'Наличие ежегодного плана информационного сопровождения государственного учреждения службы занятости населения',
                            'type': 'boolean',
                            'zone': null}]}]},
                {'id': 33,
                    'name': 'Наличие ежемесячных отчетов пресс-службы, содержащих информацию об оценке общественностью деятельности государственного учреждения службы занятости и о возможных имиджевых рисках для государственного учреждения службы занятости, балл',
                    'base_indicator_name': 'Наличие ежемесячных отчетов пресс-службы, содержащих информацию об оценке общественностью деятельности государственного учреждения службы занятости и о возможных имиджевых рисках для государственного учреждения службы занятости, балл',
                    'base_indicator_type': 'boolean',
                    'flk': 'this.period === 4 && q7 + q8 + q9 + q10 + q11 + q12 >= 1 && "Нельзя указывать положительные ответы в полях 7 - 12, если выбран период за пол года"',
                    'formula': "(CONST_INTERVAL==='fullyear' && q1 && q2 && q3 && q4 && q5 && q6 && q7 && q8 && q9 && q10 && q11 && q12)\n||\n(CONST_INTERVAL==='half_year' && q1 && q2 && q3 && q4 && q5 && q6)",
                    'level': 'УЦЗН',
                    'sources': 'Отчеты пресс-службы, содержащие информацию об оценке общественностью деятельности государственного учреждения службы занятости и о возможных имиджевых рисках для государственного учреждения службы занятости',
                    'groups': [{'id': 0,
                        'name': 'Сведения о ежемесячных отчетах пресс-службы, содержащих информацию об оценке общественностью деятельности государственного учреждения службы занятости и о возможных имиджевых рисках для государственного учреждения службы занятости',
                        'questions': [{'id': 1,
                            'name': 'Наличие отчета за январь',
                            'type': 'boolean'},
                            {'id': 2, 'name': 'Наличие отчета за февраль', 'type': 'boolean',
                                'zone': null},
                            {'id': 3, 'name': 'Наличие отчета за март', 'type': 'boolean',
                                'zone': null},
                            {'id': 4, 'name': 'Наличие отчета за апрель', 'type': 'boolean',
                                'zone': null},
                            {'id': 5, 'name': 'Наличие отчета за май', 'type': 'boolean',
                                'zone': null},
                            {'id': 6, 'name': 'Наличие отчета за июнь', 'type': 'boolean',
                                'zone': null},
                            {'id': 7, 'name': 'Наличие отчета за июль', 'type': 'boolean',
                                'zone': null},
                            {'id': 8, 'name': 'Наличие отчета за август', 'type': 'boolean',
                                'zone': null},
                            {'id': 9, 'name': 'Наличие отчета за сентябрь', 'type': 'boolean',
                                'zone': null},
                            {'id': 10, 'name': 'Наличие отчета за октябрь', 'type': 'boolean',
                                'zone': null},
                            {'id': 11, 'name': 'Наличие отчета за ноябрь', 'type': 'boolean',
                                'zone': null},
                            {'id': 12,
                                'name': 'Наличие отчета за декабрь',
                                'type': 'boolean',
                                'zone': null}]}]}]},
        {'id': 7,
            'name': 'Показатели исполнения Стандарта в части требований к расположению центров занятости населения, помещениям, в которых они функционируют и оснащению рабочих мест',
            'indicators': [{'id': 34,
                'name': 'Доля территориальных центров занятости населения, в которых соблюдаются требования руководства по фирменному стилю бренда "Работа России", согласно приложению № 2 к настоящему Стандарту, от общего количества территориальных центров занятости населения, процент',
                'base_indicator_name': 'Количество территориальных центров занятости населения, в которых соблюдаются требования руководства по фирменному стилю бренда «Работа России», утвержденного Министерством труда и социальной защиты Российской Федерации, шт',
                'base_indicator_type': 'integer',
                'flk': null,
                'formula': '(CONST_CZN_LEVEL===1 && q1 && q2 && q3 && q4 && q5 && q6 && q7 && q8 && q9 && q10 && q11 && q12 && q13 && q14 && q15 && q16 && q17 && q18 && q19 && q20 && q21 && q22 && q23 && q24 && q25 && q26 && q27 && q28 && q29 && q30 && q31 && q32)\n||\n(CONST_CZN_LEVEL===2 && q1 && q2 && q3 && q4 && q5 && q6 && q7 && q8 && q9 && q10 && q11 && q12 && q13 && q14 && q15 && q16 && q17 && q18 && q19 && q20 && q21 && q23 && q24 && q25 && q26 && q27 && q28 && q29 && q31 && q32)\n||\n(CONST_CZN_LEVEL===3 && q1 && q2 && q3 && q4 && q5 && q6 && q7 && q8 && q9 && q10 && q11 && q13 && q16 && q21 && q23 && q24 && q25 && q26 && q27 && q28 && q29 && q31 && q32)',
                'level': 'ТЦЗН',
                'sources': 'Данные субъекта Российской Федерации, подтверждающие соблюдение требований руководства по фирменному стилю бренда "Работа России", утвержденного Министерством труда и социальной защиты Российской Федерации (фото)',
                'groups': [{'id': 0,
                    'name': 'Требования к транспортной доступности ЦЗН',
                    'questions': [{'id': 1,
                        'name': 'Расстояние до остановки общественного транспорта - не более 300 метров от центра занятости',
                        'type': 'boolean',
                        'zone': null},
                        {'id': 2,
                            'name': 'Оборудование мест для парковки автотранспортных средств',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 3,
                            'name': 'Возможность остановки автомобиля или транспортного средства не далее 50 м от входной группы',
                            'type': 'boolean',
                            'zone': null}]},
                    {'id': 1,
                        'name': 'Требования к зданию и прилегающей территории ЦЗН',
                        'questions': [{'id': 4,
                            'name': 'Вывеска с полным и сокращенным наименованием ЦЗН на входе в здание, а также информацией о режиме работы',
                            'type': 'boolean',
                            'zone': null},
                            {'id': 5,
                                'name': 'Наличие уличной навигационной системы указателей',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 6,
                                'name': 'Наличие твердых покрытий подходов и подъездов к зданию, в том числе пешеходных дорожек',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 7,
                                'name': 'Соответствие прилегающей территории строительным нормам и правилам по благоустройству',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 8,
                                'name': 'Соответствие помещений требованиям пожарной, санитарно-эпидемиологической безопасности',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 9,
                                'name': 'Подвальное помещение (при наличии) только для технических служб, не допускается использование для приема граждан и работодателей',
                                'type': 'boolean',
                                'zone': null}]},
                    {'id': 2,
                        'name': 'Требования по организации пространства в помещении ЦЗН и оформлению',
                        'questions': [{'id': 10,
                            'name': 'Использование легких мобильных перегородок (стеклянных, с металлическими элементами) для организации пространства',
                            'type': 'boolean',
                            'zone': null}]},
                    {'id': 3,
                        'name': 'Требования к условиям доступности ЦЗН для инвалидов',
                        'questions': [{'id': 11,
                            'name': 'Оборудование входов в помещения для приема заявителей пандусом, расширенными проходами',
                            'type': 'boolean',
                            'zone': null},
                            {'id': 12,
                                'name': 'Наличие тактильной напольной разметки',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 13,
                                'name': 'Наличие элементов навигации для инвалидов и незрячих людей',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 14,
                                'name': 'Наличие санузла общего пользования, в том числе приспособленного для инвалидов независимо от нарушений функций организма и ограничений их жизнедеятельности',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 15,
                                'name': 'Выделено не менее 10 % мест для парковки специальных автотранспортных средства инвалидов - получателей государственных услуг',
                                'type': 'boolean',
                                'zone': null}]},
                    {'id': 4,
                        'name': 'Требования по наличию и оснащению функциональных секторов (зон) в помещении ЦЗН',
                        'questions': [{'id': 16,
                            'name': 'Наличие зоны информирования и ожидания для граждан',
                            'type': 'boolean',
                            'zone': 'Зона информирования и ожидания для граждан'},
                            {'id': 17,
                                'name': 'Наличие системы управления электронной очередью',
                                'type': 'boolean',
                                'zone': 'Зона информирования и ожидания для граждан'},
                            {'id': 18,
                                'name': 'Наличие стойки (специально оборудованного места) администратора',
                                'type': 'boolean',
                                'zone': 'Зона информирования и ожидания для граждан'},
                            {'id': 19,
                                'name': 'Наличие детской зоны или детского уголка для посетителей с детьми',
                                'type': 'boolean',
                                'zone': 'Зона информирования и ожидания для граждан'},
                            {'id': 20,
                                'name': 'Наличие телевизионных экранов для трансляции новостей региона и муниципального образования, информационно-просветительских видеороликов и рекламы вакансий',
                                'type': 'boolean',
                                'zone': 'Зона информирования и ожидания для граждан'},
                            {'id': 21,
                                'name': 'Наличие зоны информирования и ожидания для работодателей',
                                'type': 'boolean',
                                'zone': 'Зона информирования и ожидания для работодателей'},
                            {'id': 22,
                                'name': 'Наличие телевизионных экранов для трансляции новостей региона и муниципального образования, информационно-просветительских видеороликов и рекламы вакансий',
                                'type': 'boolean',
                                'zone': 'Зона информирования и ожидания для работодателей'},
                            {'id': 23,
                                'name': 'Наличие сектора цифровых сервисов для граждан и работодателей',
                                'type': 'boolean',
                                'zone': 'Сектор цифровых сервисов для граждан и работодателей'},
                            {'id': 24,
                                'name': 'Наличие зоны первичного приема граждан',
                                'type': 'boolean',
                                'zone': 'Зона первичного приема граждан'},
                            {'id': 25,
                                'name': 'Оборудование каждого окна приема компьютерами и стульями для посетителей',
                                'type': 'boolean',
                                'zone': 'Зона первичного приема граждан'},
                            {'id': 26,
                                'name': 'Наличие зоны индивидуальной работы с гражданами',
                                'type': 'boolean',
                                'zone': 'Зона индивидуальной работы с гражданами'},
                            {'id': 27,
                                'name': 'Наличие кабинетов и окон приема в зоне',
                                'type': 'boolean',
                                'zone': 'Зона индивидуальной работы с гражданами'},
                            {'id': 28,
                                'name': 'Наличие зоны индивидуальной работы с работодателями',
                                'type': 'boolean',
                                'zone': 'Зона индивидуальной работы с работодателями'},
                            {'id': 29, 'name': 'Наличие кабинетов в зоне', 'type': 'boolean','zone':'Зона индивидуальной работы с работодателями'},
                            {'id': 30,
                                'name': 'Наличие зоны коллективной работы с работодателями (коворкинга)',
                                'type': 'boolean',
                                'zone': 'Зона индивидуальной работы с работодателями'},
                            {'id': 31,
                                'name': 'Наличие зала групповых занятий',
                                'type': 'boolean',
                                'zone': 'Зал групповых занятий (конференц-зал)'},
                            {'id': 32,
                                'name': 'Оборудование помещения системой видеоконференцсвязи для проведения дистанционных занятий',
                                'type': 'boolean',
                                'zone': 'Зал групповых занятий (конференц-зал)'}]}]},
                {'id': 35,
                    'name': 'Доля территориальных центров занятости населения, расположение зданий которых соответствует установленным требованиям, от общего количества территориальных центров занятости населения, процент',
                    'base_indicator_name': 'Количество территориальных центров занятости населения, расположение зданий которых соответствует установленным требованиям, шт',
                    'base_indicator_type': 'integer',
                    'flk': null,
                    'formula': 'q1 && q2 && q3',
                    'level': 'ТЦЗН',
                    'sources': 'Акты комиссионного обследования, подписанные руководителем исполнительного органа субъекта Российской Федерации, осуществляющего полномочия в области содействия занятости населения, или иным надлежащим образом уполномоченным лицом',
                    'groups': [{'id': 0,
                        'name': 'Сведения о расположении здания',
                        'questions': [{'id': 1,
                            'name': 'Рекомендуемое расстояние до остановки общественного транспорта - не более 300 метров от территориального центра занятости населения',
                            'type': 'boolean',
                            'zone': null},
                            {'id': 2,
                                'name': 'Наличие уличной навигационной системы указателей и наружной рекламы территориального центра занятости населения',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 3,
                                'name': 'Наличие на входе в здание вывески с полным и сокращенным наименованием центра занятости населения, а также информацией о режиме работы',
                                'type': 'boolean',
                                'zone': null}]}]},
                {'id': 36,
                    'name': 'Доля территориальных центров занятости населения, количество и состав функциональных секторов (зон) которых соответствуют установленным требованиям, от общего количества территориальных центров занятости населения, процент',
                    'base_indicator_name': 'Количество территориальных центров занятости населения, количество и состав функциональных секторов (зон) которых соответствуют установленным требованиям, шт',
                    'base_indicator_type': 'integer',
                    'flk': '((q1 === 2 || q2 === 2 || q3 === 2 || q4 === 2 || q5 === 2 || q6 === 2 || q7 === 2 || q8 === 2) && this.regionAndDepartmentInfo.department?.level !== 3 && "Выбор совмещения доступен только для ЦЗН 3 уровня") ',
                    'formula': '(CONST_CZN_LEVEL===1 && q1===1 && q2===1 && q3===1 && q4===1 && q5===1 && q6===1 && q7===1 && q8===1)\n||\n(CONST_CZN_LEVEL===2 && q1===1 && q2===1 && q3===1 && q4===1 && q5===1 && q6===1 && q7===1 && q8===1)\n||\n(CONST_CZN_LEVEL===3 && q1>=1 && q2>=1 && q3>=1 && q4>=1 && q5===1 && q6>=1 && q7>=1 && q8>=1)',
                    'level': 'ТЦЗН',
                    'sources': 'Акты комиссионного обследования, подписанные руководителем исполнительного органа субъекта Российской Федерации, осуществляющего полномочия в области содействия занятости населения, или иным надлежащим образом уполномоченным лицом',
                    'groups': [{'id': 0,
                        'name': 'Функциональные сектора (зоны) помещения',
                        'questions': [{'id': 1,
                            'name': 'Зона информирования граждан',
                            'type': 'DICT_SECTORS',
                            'zone': null},
                            {'id': 2,
                                'name': 'Зона информирования работодателей',
                                'type': 'DICT_SECTORS',
                                'zone': null},
                            {'id': 3,
                                'name': 'Сектора цифровых сервисов для граждан',
                                'type': 'DICT_SECTORS',
                                'zone': null},
                            {'id': 4,
                                'name': 'Сектора цифровых сервисов для работодателей',
                                'type': 'DICT_SECTORS',
                                'zone': null},
                            {'id': 5,
                                'name': 'Зона первичного приема граждан',
                                'type': 'DICT_SECTORS',
                                'zone': null},
                            {'id': 6,
                                'name': 'Зона индивидуальной работы с гражданами',
                                'type': 'DICT_SECTORS',
                                'zone': null},
                            {'id': 7,
                                'name': 'Зона индивидуальной работы с работодателями',
                                'type': 'DICT_SECTORS',
                                'zone': null},
                            {'id': 8,
                                'name': 'Зал групповых занятий (конференц-зал)',
                                'type': 'DICT_SECTORS',
                                'zone': null}]}]},
                {'id': 37,
                    'name': 'Доля территориальных центров занятости населения, зона информирования граждан которых соответствует установленным требованиям, от общего количества территориальных центров занятости населения, процент',
                    'base_indicator_name': 'Количество территориальных центров занятости населения, зона информирования граждан которых соответствуют установленным требованиям, шт',
                    'base_indicator_type': 'integer',
                    'flk': null,
                    'formula': '(CONST_CZN_LEVEL===1 && q1 && q2 && q3 && q4 && q5 && q6 && q7 && q8 && q9 && q8 && q10 && q11 && q12 && q13 && q14 && q15 && q16 && q17 && q18 && q19 && q20 && q21)\n||\n(CONST_CZN_LEVEL===2 && q1 && q2 && q3 && q4 && q5 && q6 && q7 && q8 && q9 && q8 && q10 && q11 && q12 && q13 && q14 && q15 && q16 && q17 && q18 && q19 && q20 && q21)\n||\n(CONST_CZN_LEVEL===3 && q1 && q2 && q8 && q9 && q8 && q10 && q11 && q12 && q13 && q14 && q15 && q16 && q17 && q18 && q19 && q20 && q21)',
                    'level': 'ТЦЗН',
                    'sources': 'Акты комиссионного обследования, подписанные руководителем исполнительного органа субъекта Российской Федерации, осуществляющего полномочия в области содействия занятости населения, или иным надлежащим образом уполномоченным лицом',
                    'groups': [{'id': 0,
                        'name': 'Сведения о зоне информирования граждан',
                        'questions': [{'id': 1,
                            'name': 'Наличие сектора ожидания',
                            'type': 'boolean',
                            'zone': null},
                            {'id': 2,
                                'name': 'Наличие сектора цифровых сервисов',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 3,
                                'name': 'Наличие системы управления очередью, позволяющей вести учет граждан и работодателей, осуществивших предварительную запись',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 4,
                                'name': 'Наличие системы управления очередью, позволяющей вести учет граждан и работодателей, находящихся в очереди, также в зависимости от видов государственных услуг',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 5,
                                'name': 'Наличие системы управления очередью, позволяющей отображать статус очереди',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 6,
                                'name': 'Наличие системы управления очередью, позволяющей автоматически перенаправлять граждан и работодателей в очередь на обслуживание к следующему работнику центра занятости населения',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 7,
                                'name': 'Наличие системы управления очередью, позволяющей формировать отчеты о посещаемости территориального центра занятости населения, очередях, среднем времени ожидания (обслуживания) и о загруженности работников',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 8,
                                'name': 'Наличие специально оборудованного рабочего места (стойки) администратора, предназначенного для оптимизации потока граждан и работодателей и сокращения вероятности возникновения очередей, в пользовании справочно-информационными ресурсами, а также для предоставления иной информации',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 9,
                                'name': 'Наличие на стойке книги отзывов и предложений',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 10,
                                'name': 'Наличие на стойке средств для оказания первой медицинской помощи',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 11,
                                'name': 'Наличие на стойке информации о вышестоящих организациях: почтовые адреса, телефоны, адреса официальных сайтов и электронной почты с указанием контактных данных ответственных лиц',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 12,
                                'name': 'Наличие на стойке информации о возможности бесплатного копирования документов',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 13,
                                'name': 'Наличие рядом со стойкой копировального аппарата для предварительного бесплатного копирования гражданами и работодателями документов, необходимых для предоставления государственных услуг',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 14,
                                'name': 'Наличие цифровых сервисов, включающих программно-аппаратный комплекс, обеспечивающий доступ клиентов к единой цифровой платформе, единому порталу, региональному порталу и иным порталам, интегрированным с единой системой идентификации и аутентификации, а также персональных компьютеров с доступом к информационно-телекоммуникационной сети Интернет',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 15,
                                'name': 'Наличие информационных стендов или иных источников информирования',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 16,
                                'name': 'Наличие стульев, кресельных секций или скамей (банкеток) и столов (стоек) для оформления документов с размещением на них форм (бланков) документов, необходимых для получения государственных услуг, достаточного количества бумаги формата А4 и канцелярских принадлежностей',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 17,
                                'name': 'Наличие оборудованной детской комнаты или детского уголка для посетителей с детьми',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 18,
                                'name': 'Наличие аппаратов (аппарата) для раздачи охлажденной питьевой воды в стаканы (кулеры) или бутилированной питьевой воды',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 19,
                                'name': 'Наличие телевизионных экранов для трансляции новостей региона и муниципального образования, информационно-просветительских видеороликов и рекламы вакансий (не менее одного на каждые 50 м 2 площади сектора)',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 20,
                                'name': 'Наличие доступных мест общественного пользования и мест для хранения верхней одежды',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 21,
                                'name': 'Наличие отчетов о посещаемости территориального центра занятости населения, очередях, среднем времени ожидания (обслуживания) и о загруженности работников',
                                'type': 'boolean',
                                'zone': null}]}]},
                {'id': 38,
                    'name': 'Доля территориальных центров занятости населения, сектор первичного приема граждан которых соответствует установленным требованиям, от общего количества территориальных центров занятости населения, процент',
                    'base_indicator_name': 'Количество территориальных центров занятости населения, сектор первичного приема граждан которых соответствуют установленным требованиям, шт',
                    'base_indicator_type': 'integer',
                    'flk': null,
                    'formula': 'q1 && q2 && q3 && q4 && q5',
                    'level': 'ТЦЗН',
                    'sources': 'Акты комиссионного обследования, подписанные руководителем исполнительного органа субъекта Российской Федерации, осуществляющего полномочия в области содействия занятости населения, или иным надлежащим образом уполномоченным лицом',
                    'groups': [{'id': 0,
                        'name': 'Сведения о секторе первичного приема граждан',
                        'questions': [{'id': 1,
                            'name': 'Наличие в "окнах приема" компьютеров',
                            'type': 'boolean',
                            'zone': null},
                            {'id': 2,
                                'name': 'Наличие в "окнах приема" дополнительных мониторов, клавиатуры и компьютерной мыши, или планшета, которыми может воспользоваться посетитель',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 3,
                                'name': 'Наличие в "окнах приема" стульев (не менее двух у каждого стола)',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 4,
                                'name': 'Наличие в "окнах приема" столов',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 5,
                                'name': 'Наличие в "окнах приема" информационных табличек с указанием номера окна, фамилии, имени, отчества (при наличии) и должности работника центра занятости населения;',
                                'type': 'boolean',
                                'zone': null}]}]},
                {'id': 39,
                    'name': 'Доля территориальных центров занятости населения, зона индивидуальной работы с гражданами и зона индивидуальной работы с работодателями, которых соответствуют установленным требованиям, от общего количества территориальных центров занятости населения, процент',
                    'base_indicator_name': 'Количество территориальных центров занятости населения, зона индивидуальной работы с гражданами и зона индивидуальной работы с работодателями которых соответствуют установленным требованиям, шт',
                    'base_indicator_type': 'integer',
                    'flk': null,
                    'formula': 'q1 && q2 && q3 && q4',
                    'level': 'ТЦЗН',
                    'sources': 'Акты комиссионного обследования, подписанные руководителем исполнительного органа субъекта Российской Федерации, осуществляющего полномочия в области содействия занятости населения, или иным надлежащим образом уполномоченным лицом',
                    'groups': [{'id': 0,
                        'name': 'Сведения о зоне индивидуальной работы с гражданами',
                        'questions': [{'id': 1,
                            'name': 'Наличие специально выделенных секторов (в целях организации переговоров, проведения консультаций, оказания государственных услуг, требующих индивидуального взаимодействия)',
                            'type': 'boolean',
                            'zone': null},
                            {'id': 2,
                                'name': 'Наличие отдельных кабинетов (в целях организации переговоров, проведения консультаций, оказания государственных услуг, требующих индивидуального взаимодействия)',
                                'type': 'boolean',
                                'zone': null}]},
                        {'id': 1,
                            'name': 'Сведения о зоне индивидуальной работы с работодателями',
                            'questions': [{'id': 3,
                                'name': 'Наличие специально выделенных секторов (в целях организации переговоров, проведения консультаций, оказания государственных услуг, требующих индивидуального взаимодействия)',
                                'type': 'boolean',
                                'zone': null},
                                {'id': 4,
                                    'name': 'Наличие отдельных кабинетов (в целях организации переговоров, проведения консультаций, оказания государственных услуг, требующих индивидуального взаимодействия)',
                                    'type': 'boolean',
                                    'zone': null}]}]},
                {'id': 40,
                    'name': 'Доля территориальных центров занятости населения, зал групповых занятий (конференц-зал) которых соответствуют установленным требованиям, от общего количества территориальных центров занятости населения, процент',
                    'base_indicator_name': 'Количество территориальных центров занятости населения, зал групповых занятий (конференц-зал) которых соответствуют установленным требованиям, шт',
                    'base_indicator_type': 'integer',
                    'flk': null,
                    'formula': 'q1 && q2',
                    'level': 'ТЦЗН',
                    'sources': 'Акты комиссионного обследования, подписанные руководителем исполнительного органа субъекта Российской Федерации, осуществляющего полномочия в области содействия занятости населения, или иным надлежащим образом уполномоченным лицом',
                    'groups': [{'id': 0,
                        'name': 'Сведения о зале групповых занятий (конференц-зале)',
                        'questions': [{'id': 1,
                            'name': 'Наличие специально выделенного помещения для проведения групповых занятий, информационных, обучающих и иных мероприятий с гражданами и работодателями',
                            'type': 'boolean',
                            'zone': null},
                            {'id': 2,
                                'name': 'Оборудование специально выделенного помещения системой видеоконференцсвязи для проведения дистанционных занятий',
                                'type': 'boolean',
                                'zone': null}]}]},
                {'id': 41,
                    'name': 'Доля территориальных центров занятости населения, условия доступности для инвалидов которых соответствуют установленным требованиям, от общего количества территориальных центров занятости населения, процент',
                    'base_indicator_name': 'Количество территориальных центров занятости населения, условия доступности для инвалидов которых соответствуют установленным требованиям, шт',
                    'base_indicator_type': 'integer',
                    'flk': null,
                    'formula': '(CONST_CZN_LEVEL===1 && q1 && q2 && q3 && q4 && q5 && q6 && q7 && q8 && q9 && q8 && q10 && q11)\n||\n(CONST_CZN_LEVEL===2 && q1 && q2 && q3 && q4 && q5 && q6 && q7 && q8 && q9 && q8 && q10 && q11)\n||\n(CONST_CZN_LEVEL===3 && q8 && q9 && q8 && q10 && q11)',
                    'level': 'ТЦЗН',
                    'sources': 'Акты комиссионного обследования, подписанные руководителем исполнительного органа субъекта Российской Федерации, осуществляющего полномочия в области содействия занятости населения, или иным надлежащим образом уполномоченным лицом',
                    'groups': [{'id': 0,
                        'name': 'Сведения об условиях доступности для инвалидов',
                        'questions': [{'id': 1,
                            'name': 'Выделение не менее 10 процентов мест (но не менее одного места) для парковки специальных автотранспортных средств инвалидов',
                            'type': 'boolean',
                            'zone': null},
                            {'id': 2,
                                'name': 'Оборудование входов в помещения (здания) пандусами, расширенными проходами, позволяющими обеспечить беспрепятственный доступ инвалидов, использующих кресла-коляски',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 3,
                                'name': 'Обеспечение беспрепятственного входа в территориальный центр занятости населения и выхода из него',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 4,
                                'name': 'Обеспечение возможности самостоятельного передвижения по территории, на которой расположены объекты (здания, помещения), в которых предоставляются государственные услуги, а также входа на такие объекты и выхода из них, посадки в транспортное средство и высадки из него, в том числе с использованием кресла-коляски',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 5,
                                'name': 'Надлежащее размещение оборудования и носителей информации, необходимых для обеспечения беспрепятственного доступа к объектам (зданиям, помещениям), в которых предоставляется государственная услуга, с учетом ограничений жизнедеятельности',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 6,
                                'name': 'Сопровождение инвалидов, имеющих стойкие нарушения функции зрения и самостоятельного передвижения по территории территориального центра занятости населения',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 7,
                                'name': 'Содействие инвалиду при входе в территориальный центр занятости населения и выходе из него',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 8,
                                'name': 'Информирование инвалида о доступных маршрутах общественного транспорта',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 9,
                                'name': 'Надлежащее размещение носителей информации, необходимой для обеспечения беспрепятственного доступа инвалидов к зданию центра занятости населения и о государственной услуге, с учетом ограничений их жизнедеятельности, в том числе дублирование необходимой для получения государственной услуги звуковой и зрительной информации, а также надписей, знаков и иной текстовой и графической информации знаками, выполненными рельефно-точечным шрифтом Брайля и на контрастном фоне',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 10,
                                'name': 'Обеспечение допуска в центр занятости населения собаки-проводника при наличии документа, подтверждающего ее специальное обучение',
                                'type': 'boolean',
                                'zone': null},
                            {'id': 11,
                                'name': 'Предоставление инвалидам по слуху, при необходимости, услуги с использованием русского жестового языка, включая обеспечение допуска на объект сурдопереводчика, тифлопереводчика',
                                'type': 'boolean',
                                'zone': null}]}]}]},
        {'id': 8,
            'name': 'Показатели исполнения Стандарта в части требований к системам информатизации и автоматизации государственных учреждений службы занятости',
            'indicators': [{'id': 42,
                'name': 'Доля территориальных центров занятости населения, рабочие места работников, оказывающих государственные услуги на единой цифровой платформе, в которых соответствуют требованиям, устанавливаемым Федеральной службой по труду и занятости (оператором единой цифровой платформы), от общего количества территориальных центров занятости населения, процент',
                'base_indicator_name': 'Количество территориальных центров занятости населения, рабочие места, сотрудников, оказывающих государственные услуги на единой цифровой платформе, в которых соответствуют требованиям, устанавливаемым Федеральной службой по труду и занятости (оператором единой цифровой платформы), шт',
                'base_indicator_type': 'integer',
                'flk': null,
                'formula': 'q1 && q2 && q3 && q4 && q5 && q6 && q7 && q8 && q9 && q10 && q11 && q12 && q13 && q14 && q15 && q16 && q17 && q18',
                'level': 'ТЦЗН',
                'sources': 'Документы, подтверждающие факт наличия рабочих мест, в том числе: аттестат рабочего места, акт о создании (оснащении) рабочего места, акт закрепления рабочего места, штатное расписание государственного учреждения службы занятости населения',
                'groups': [{'id': 0,
                    'name': 'Требования к рабочим местам',
                    'questions': [{'id': 1,
                        'name': 'Установлен процессор Intel Core 2 Duo или более поздней',
                        'type': 'boolean',
                        'zone': null},
                        {'id': 2,
                            'name': 'Установлена оперативная память объемом не менее 2 Гб',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 3,
                            'name': 'Установлен видеоадаптер типа SVGA, обеспечивающий отображение 16 bit цветов в разрешении 1366 х 768 точек',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 4,
                            'name': 'Установлен монитор, обеспечивающий отображение 16 bit цветов в разрешении 1366 х 768 точек',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 5,
                            'name': 'Установлен жесткий диск с объемом свободного пространства не менее 2 Гб',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 6, 'name': 'Установлен сетевой адаптер', 'type': 'boolean',
                            'zone': null},
                        {'id': 7,
                            'name': 'Установлена 101 клавишная или Windows-совместимая клавиатура',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 8,
                            'name': 'Установлена windows-совместимая мышь',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 9, 'name': 'Установлен принтер', 'type': 'boolean',
                            'zone': null},
                        {'id': 10,
                            'name': 'Подключен к сети Интернет по каналу с пропускной способностью не менее 512 кб/с',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 11,
                            'name': 'Установлена одна из следующих операционных систем: Windows 7 или новее, macOS 10.9 или новее, Ubuntu 14.04 или новее, Debian 8 или новее, Astra Linux Сommon Edition 1.10 или новее, Astra Linux Special Edition 1.4 или новее',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 12,
                            'name': 'Установлен один из следующих Web-браузеров: Mozilla Firefox версии 61 или новее,Google Chrome версии 72 или новее, Opera версии 60 или новее',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 13,
                            'name': 'Установлен пакет Microsoft Office 2003 или совместимый для работы с документами формата DOC, RTF, XLS',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 14,
                            'name': 'Установлен Adobe Acrobat Reader версии 6.0 или новее для просмотра документов в формате PDF',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 15, 'name': 'Установлен драйвер принтера', 'type': 'boolean',
                            'zone': null},
                        {'id': 16,
                            'name': 'Установлено антивирусное программное обеспечение',
                            'type': 'boolean',
                            'zone': null},
                        {'id': 17, 'name': 'Установлен ViPNet клиент', 'type': 'boolean',
                            'zone': null},
                        {'id': 18,
                            'name': 'Установлены сертификаты Удостоверяющего Центра Минкомсвязи',
                            'type': 'boolean',
                            'zone': null}]}]}]},
        {'id': 9,
            'name': 'Показатели исполнения Стандарта в части требований к системе контроля и оценки качества деятельности государственного учреждения службы занятости по осуществлению полномочий в области содействия занятости населения',
            'indicators': [{'id': 43,
                'name': 'Наличие в государственном учреждении службы занятости утвержденного порядка сбора сведений, необходимых для расчета показателей исполнения государственным учреждением службы занятости требований Стандарта, балл',
                'base_indicator_name': 'Наличие в государственном учреждении службы занятости утвержденного порядка сбора сведений, необходимых для расчета показателей исполнения государственным учреждением службы занятости требований Стандарта, балл',
                'base_indicator_type': 'boolean',
                'flk': null,
                'formula': 'q1',
                'level': 'УЦЗН',
                'sources': 'Утверждаемый руководителем государственного учреждения службы занятости населения порядок сбора сведений, необходимых для расчета показателей исполнения государственным учреждением службы занятости населения требований Стандарта',
                'groups': [{'id': 0,
                    'name': 'Сведения о порядке сбора сведений, необходимых для расчета показателей исполнения государственным учреждением службы занятости населения требований Стандарта',
                    'questions': [{'id': 1,
                        'name': 'Наличие в государственном учреждении службы занятости населения утвержденного порядка сбора сведений, необходимых для расчета показателей исполнения государственным учреждением службы занятости населения требований Стандарта',
                        'type': 'boolean',
                        'zone': null}]}]}]}]}
